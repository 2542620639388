import { useNavigate } from 'react-router-dom';
import loggedIcon from '../../../../assets/images/loggedicon.png';
import { ROUND_BUTTON } from '../../../config/constants';
import { useAuth } from '../../../contexts/authContext';
import MainButton from '../../buttons/MainButton';
import { useSubscription } from '../../../contexts/subscriptionContext';
import { useEffect, useState } from 'react';


const PrincipalFeature = () => {

  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [trialModeActive, setTrialModeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        setIsLoading(true);
        const hasSubscription = await subscription.isActive()
        setSubscriptionActive(hasSubscription)
        const trialMode = await subscription.isTrialModeActive();
        setTrialModeActive(trialMode)

      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);


  return (
    <div className="flex flex-row flex-wrap-reverse items-center justify-center p-12 xl:px-60">
      <div className="flex flex-col items-center w-full sm:w-1/2 p-6">
        <img src={loggedIcon} alt={'App Auténtica Test'} />
      </div>
      <div className="flex flex-col items-center text-center sm:items-start sm:text-left font-sans w-full sm:w-1/2 text-left sm:px-6">
        <h1 className="text-3xl md:text-5xl text-black font-semibold mb-5">¡Cada día más cerca de conseguir tu objetivo!</h1>
        <MainButton
          className="w-40"
          type={ROUND_BUTTON}
          disabled={isLoading}
          onClick={() => {
            if (!subscriptionActive && !trialModeActive) {
              navigate('/tests/simulacrum');
            } else {
              navigate('/tests');
            }
          }}>
          ¡Comienza ahora!
        </MainButton>
      </div>
    </div>
  );
};

export { PrincipalFeature };