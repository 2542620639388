import { Fragment, useState } from "react";
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import PaymentService from "../../services/httpServices/payment.service";
import SubscriptionFailureModal from "./SubscriptionFailureModalParent";
import { useAuth } from "../../contexts/authContext";
import paymentServiceStripe from "../../services/paymentStripe.service";
import { useNavigate } from "react-router-dom";


type OptionalFunctionType = () => void;

interface NoSubscriptionModalProps {
  onClose?: OptionalFunctionType | null;
}


const NoSubscriptionModal: React.FC<NoSubscriptionModalProps> = ({ onClose }) => {
  const { createCheckoutSession } = PaymentService();
  const auth = useAuth();

  return (
    <SubscriptionFailureModal
      onClose={() => onClose?.()}
      title="No estás suscrito"
      description="Solo podrás realizar pruebas en modo simulacro."
      actionText="Suscribirme"
      actionFunction={async () => {
        const createSesion = await createCheckoutSession(auth.user.email);
        await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
      }}
    />
  );
};

const NoPaidModal: React.FC<NoSubscriptionModalProps> = ({ onClose }) => {
  const { billingDashboard } = PaymentService();
  const navigate = useNavigate();

  return (
    <SubscriptionFailureModal
      onClose={() => onClose?.()}
      title="Problema con tus datos de pago"
      description="Solo podrás realizar pruebas en modo simulacro."
      actionText="Revisar datos de pago"
      actionFunction={async () => {
        const url = await billingDashboard()
        window.location.href = url
      }}
    />
  );
};


const TrialModeFinished: React.FC<NoSubscriptionModalProps> = ({ onClose }) => {
  const { createCheckoutSession } = PaymentService();
  const auth = useAuth();

  return (
    <SubscriptionFailureModal
      onClose={() => onClose?.()}
      title="Tu periodo de prueba ha vencido"
      description="Solo podrás realizar pruebas en modo simulacro."
      actionText="Suscribirme"
      actionFunction={async () => {
        const createSesion = await createCheckoutSession(auth.user.email);
        await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
      }}
    />
  );
};

export { NoSubscriptionModal, NoPaidModal, TrialModeFinished };
