import { useEffect, useState } from 'react';
import LoggedHomeComponent from '../components/home/logged/LoggedHome';
import { useAuth } from '../contexts/authContext';
import UserService from '../services/httpServices/user.service';
import Test3ClicksIcon from '../../assets/images/navbaricon3clicks.png';
import HomeFooter from '../components/home/HomeFooter';
import NoLoggedHome from '../components/home/nologged/NoLoggedHome';
import PillTrialMode from '../components/pillTrialMode/pillTrialMode';


export default function HomeView(): JSX.Element {

  const auth = useAuth();
  const { getInfoUser } = UserService();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.token) getUserData();
    else setIsLoading(false);
  }, [auth.token]);

  const getUserData = async () => {
    getInfoUser().then(res => {
      auth.updateUser(res)
    }).catch(err => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className='flex flex-col flex-1'>
      {auth.token &&
        <div className='sm:hidden pt-12'>
          <img
            src={Test3ClicksIcon}
            className="w-[70%] min-w-[200px] m-auto"
            alt="Auténtica test logo"
          />
        </div>
      }
      {!isLoading &&
        <div className='flex flex-col justify-center flex-1 pt-8'>
          {
            auth.token ? <LoggedHomeComponent /> : <NoLoggedHome />
          }
        </div>
      }
      <HomeFooter />
    </div>
  );
}