import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/authContext";
import UserService from "../../../services/httpServices/user.service";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from 'react-icons/go';
import { NoPaidModal, NoSubscriptionModal } from "../../../components/home/SubscriptionFailureModals";
import { useSubscription } from "../../../contexts/subscriptionContext";
import Skeleton from 'react-loading-skeleton';




export default function SelectSimulacrum(): JSX.Element {

  const auth = useAuth();
  const navigate = useNavigate();
  const { getInfoUser } = UserService();
  const subscription = useSubscription();

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [trialModeActive, setTrialModeActive] = useState(false);



  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser({ ...user, hasDetails: true });
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (!auth.token) setIsLoading(false);
  }, []);


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const hasSubscription = await subscription.isActive()
        setSubscriptionActive(hasSubscription)
        const hasFailed = await subscription.paymentHasFailed();
        setPaymentFailed(hasFailed);
        const hasSub = await subscription.hasSubscription();
        setHasSubscription(hasSub);
        const trialMode = await subscription.isTrialModeActive();
        setTrialModeActive(trialMode)
        if (auth.token && !auth.user.hasDetails) getUserData();
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, [auth.user]);

  console.log({ paymentFailed, subscriptionActive, hasSubscription })

  return (
    <div className="relative flex flex-col flex-1">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => {
          if (subscriptionActive || trialModeActive) navigate(-1)
          else navigate('/')
        }}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      {isLoading ?
        (
          <div className="flex-1 flex flex-col lg:flex-row">
            <div className="p-10 flex-1 flex flex-col items-center justify-center">
              <Skeleton height={50} width={200} />
              <Skeleton circle height={32} width={32} className='my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]' />
              <Skeleton height={30} width={300} />
            </div>
            <div className="p-10 flex-1 flex flex-col items-center justify-center  bg-primary-100 ">
              <Skeleton height={50} width={200} />
              <Skeleton circle height={32} width={32} className='my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]' />
              <Skeleton height={30} width={300} />
            </div>
          </div>
        ) :
        <>
          {paymentFailed &&
            <NoPaidModal />
          }
          {!hasSubscription && !trialModeActive &&
            <NoSubscriptionModal />
          }
          <div className="flex-1 flex flex-col lg:flex-row">
            <div
              className="p-10 transition-all ease-in-out text-base hover:text-2xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-white cursor-pointer text-primary-100"
              onClick={() => navigate('/tests/simulacrum/GVA')}>
              <div className="text-center font-semibold text-4xl">
                GVA
              </div>
              <div className='w-[2rem] h-[2rem] bg-primary-100 rounded-full my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]'></div>
              <div className="text-center font-semibold">
                Oposición Administración General <br></br> de la Generalitat Valenciana
              </div>
            </div>

            <div
              className="p-10 transition-all text-base ease-in-out hover:text-2xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-primary-100 cursor-pointer text-white"
              onClick={() => navigate('/tests/simulacrum/LOCAL')}>
              <div className="text-center font-semibold text-4xl">
                LOCAL
              </div>
              <div className='w-[2rem] h-[2rem] bg-white rounded-full my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]'></div>
              <div className="text-center font-semibold">
                Oposición Administración Local, <br></br> Ayuntamientos o Diputaciones de la <br></br> Comunidad Valenciana
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};
