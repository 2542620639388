import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext";
import UserService from "../../../services/httpServices/user.service";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON, ROUND_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from "react-icons/go";
import { useSubscription } from "../../../contexts/subscriptionContext";
import Skeleton from 'react-loading-skeleton';



export default function SelectGVA(): JSX.Element {

  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const { getInfoUser } = UserService();

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [trialModeActive, setTrialModeActive] = useState(false)

  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser(user);
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }




  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const hasSubscription = await subscription.isActive()
        setSubscriptionActive(hasSubscription)
        const trialMode = await subscription.isTrialModeActive();
        setTrialModeActive(trialMode)
        if (auth.token && !auth.user.hasDetails) getUserData();
        else setIsLoading(false);
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  const data = [
    {
      name: 'A1',
      ope: "OPE 2024",
      trialUrl1: `/tests/trialing/${'63d94e6606536d4291f8df67'}`,
      trialUrl2: `/tests/trialing/${'63d94f0406536d4291f8df6b'}`,
      url1: '/tests/Simulacro A1 GVA',
      url2: '/tests/Simulacro A1 GVA Concurso Oposición',
    },
    {
      name: 'A2',
      ope: "OPE 2024",
      trialUrl1: `/tests/trialing/${'63d94f3206536d4291f8df6d'}`,
      trialUrl2: `/tests/trialing/${'63ceb658a3ce37c2cf7489af'}`,
      url1: '/tests/Simulacro A2 GVA',
      url2: '/tests/Simulacro A2 GVA Concurso Oposición',
    },
    {
      name: 'C1',
      ope: "OPE 2024",

      trialUrl1: `/tests/trialing/${'63d94fff06536d4291f8df71'}`,
      trialUrl2: `/tests/trialing/${'63ceb859a3ce37c2cf7489bc'}`,
      url1: '/tests/Simulacro C1 GVA',
      url2: '/tests/Simulacro C1 GVA Concurso Oposición',
    },
    {
      name: 'C2',
      ope: "OPE 2023",
      trialUrl1: `/tests/trialing/${'612a70f832cd8310b20ff302'}`,
      trialUrl2: `/tests/trialing/${'63ceb972a3ce37c2cf7489c4'}`,
      url1: '/tests/Simulacro C2 GVA',
      url2: '/tests/Simulacro C2 GVA Concurso Oposición',
    },
  ];

  return (
    <div className="relative flex-1 flex flex-col items-center justify-center bg-background-200">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => navigate(-1)}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      <h1 className="mt-28 sm:mt-0 text-4xl text-right mr-[-1em] tracking-[1em] text-text-300 font-light">GVA</h1>
      <div className="w-full flex items-center justify-center px-8 py-20">
        {isLoading ? (
          <div className="w-full flex flex-wrap flex-col justify-center sm:flex-row gap-7" >
            {
              data.map((item, index) => (
                <Skeleton key={index} className="flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-11" height={260} borderRadius={12} />
              ))
            }
          </div>
        ) :
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
            {data?.map((item: any, index: number) => {
              return (
                <div
                  className='flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-110'
                  key={index}>
                  <span className="select-none font-bold text-5xl my-12">{item.name}</span>
                  <div className="w-full flex flex-col items-center gap-3">
                    {auth.token && (subscriptionActive || trialModeActive) ?
                      <MainButton
                        className="w-full"
                        type={ROUND_BUTTON}
                        onClick={() => navigate(item.url1)}>
                        {
                          item.ope
                        }
                      </MainButton>
                      :
                      <MainButton
                        className="w-full"
                        type={ROUND_BUTTON}
                        onClick={() => navigate(item.trialUrl2)}>
                        Estabilización (prueba)
                      </MainButton>
                    }
                  </div>
                </div>
              );
            })
            }
          </div>
        }
      </div>
    </div>
  );
};
