import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext";
import UserService from "../../../services/httpServices/user.service";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON, ROUND_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from "react-icons/go";
import { NoPaidModal, NoSubscriptionModal } from "../../../components/home/SubscriptionFailureModals";
import { useSubscription } from "../../../contexts/subscriptionContext";
import Skeleton from "react-loading-skeleton";


export default function SelectLocal(): JSX.Element {

  const auth = useAuth();
  const navigate = useNavigate();
  const subscription = useSubscription();
  const { getInfoUser } = UserService();
  const [openSubscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [trialModeActive, setTrialModeActive] = useState(false);


  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser({ ...user, hasDetails: true });
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const hasSubscription = await subscription.isActive()
        setSubscriptionActive(hasSubscription)
        const hasFailed = await subscription.paymentHasFailed();
        setPaymentFailed(hasFailed);
        const hasSub = await subscription.hasSubscription();
        setHasSubscription(hasSub);
        const trialMode = await subscription.isTrialModeActive();
        setTrialModeActive(trialMode)
        if (auth.token && !auth.user.hasDetails) getUserData();
        else setIsLoading(false);
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  const data = [
    {
      name: 'A1',
      trialUrl: `/tests/trialing/${'63d950f706536d4291f8df79'}`,
      url1: '/tests/Simulacro A1 LOCAL'
    },
    {
      name: 'A2',
      trialUrl: `/tests/trialing/${'63d9513e06536d4291f8df7d'}`,
      url1: '/tests/Simulacro A2 LOCAL',
    },
    {
      name: 'C1',
      trialUrl: `/tests/trialing/${'63d9516106536d4291f8df7f'}`,
      url1: '/tests/Simulacro C1 LOCAL',
    },
    {
      name: 'C2',
      trialUrl: `/tests/trialing/${'63d9518506536d4291f8df81'}`,
      url1: '/tests/Simulacro C2 LOCAL',
    },
  ];

  return (
    <div className="relative flex-1 flex flex-col items-center justify-center bg-background-200">
      {openSubscriptionModal && paymentFailed &&
        <NoPaidModal onClose={() => setSubscriptionModal(false)} />
      }
      {openSubscriptionModal && !hasSubscription &&
        <NoSubscriptionModal onClose={() => setSubscriptionModal(false)} />
      }
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => navigate(-1)}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      <h1 className="mt-28 sm:mt-0 text-4xl text-right mr-[-1em] tracking-[1em] text-text-300 font-light">LOCAL</h1>
      <div className="w-full flex items-center justify-center px-8 py-20">
        {isLoading ? (
          <div className="w-full flex flex-wrap flex-col justify-center sm:flex-row gap-7" >
            {
              data.map((item, index) => (
                <Skeleton key={index} className="flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-11" height={260} borderRadius={12} />
              ))
            }
          </div>
        ) :
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
            {data?.map((item: any, index: number) => {
              return (
                <div
                  className='flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-110'
                  key={index}>
                  <span className="select-none font-bold text-5xl my-14">{item.name}</span>
                  <div className="w-full flex flex-col items-center gap-3">
                    <MainButton
                      className="w-full"
                      type={ROUND_BUTTON}
                      onClick={() => navigate(item.trialUrl)}>
                      Realizar prueba
                    </MainButton>
                    <MainButton
                      className="w-full"
                      type={ROUND_BUTTON}
                      onClick={() => {
                        if (auth.token && (subscriptionActive || trialModeActive)) {
                          navigate(item.url1)
                        } else {
                          setSubscriptionModal(true)
                        }
                      }}>
                      Realizar test
                    </MainButton>
                  </div>
                </div>
              );
            })
            }
          </div>
        }
      </div>
    </div >
  );
};
