import { BaseSyntheticEvent, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import errorService from '../../services/error.service';
import paymentServiceStripe from '../../services/paymentStripe.service';
import { IFormValues } from '../../interfaces/types';
import PasswordInput from '../inputs/PasswordInput';
import TextInput from '../inputs/TextInput';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PaymentService from '../../services/httpServices/payment.service';
import UserService from '../../services/httpServices/user.service';
import MainButton from '../buttons/MainButton';
import { Button, Spinner } from 'flowbite-react';
import { redirect, useNavigate } from 'react-router-dom';
import AuthService from '../../services/authService';


export default function RegisterForm(props: any) {

  const { createCheckoutSession } = PaymentService();
  const { registerUser, registerUserWithTrial } = UserService();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre es requerido'),
    email: Yup.string().email('El email debe de ser un email valido')
      .required('El email  es requerido'),
    lastname: Yup.string()
      .optional(),
    password: Yup.string()
      .required('La contraseña es requerida').min(8, 'La longitud minima debe de ser 8'),
    confirmPassword: Yup.string()
      .required('La confirmación de contraseña es requerida').min(8, 'La longitud minima debe de ser 8')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IFormValues>({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  const email = useWatch({ control, name: 'email', defaultValue: '' });
  const password = useWatch({ control, name: 'password', defaultValue: '' });
  const confirmPassword = useWatch({ control, name: 'confirmPassword', defaultValue: '' });
  const name = useWatch({ control, name: 'name', defaultValue: '' });
  const lastname = useWatch({ control, name: 'lastname', defaultValue: '' });

  const [registerError, setRegisterError] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);
  const [checkLegal, setCheckLegal] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = AuthService();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormValues> = async (formData, event: any) => {
    const { email, password, name, lastname } = formData;
    const checkInfoEmail = checkEmail ? 'subscrito' : 'no subscrito';
    const submitter = event.nativeEvent.submitter;
    if (submitter.value === "subscription") {
      setIsLoading(true);
      try {
        await registerUser({ email, password, name, last_name: lastname, checkEmail: checkInfoEmail, status_registered: 'registration process' });
        await login(email, password);
        await redirectAccordingToEvent(event)
      } catch (error: unknown) {
        displayErrors(error);
      } finally {
        setIsLoading(false);
      }
    } else if (submitter.value === "trial-mode") {
      setIsLoading(true);
      try {
        await registerUserWithTrial({ email, password, name, last_name: lastname, checkEmail: checkInfoEmail, status_registered: 'registration process' });
        await login(email, password);
        navigate('/')
      } catch (error: unknown) {
        console.log(error, 'el errror data')
        displayErrors(error);
      } finally {
        setIsLoading(false);
      }
    }


  };



  const shadowStyle = "sm:shadow-xl";

  return (
    <div className={`w-full p-10 bg-white rounded-lg sm:max-w-xl ${props.shadow && shadowStyle}`}>
      <h1 className="text-3xl font-semibold text-center text-primary-100">
        Registro
      </h1>
      {isLoading ?
        <div className='text-center my-6'>
          <Spinner className="fill-primary-100"></Spinner>
        </div> :
        <>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-700"
              >
                Email*
              </label>
              <TextInput
                placeholder="Email"
                label="email"
                type="email"
                register={register}
                minLength={{ value: 1, message: 'El email no puede estar vacio' }}
                error={errors.email}
              />
              {errors.email?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="font-base text-sm text-red-600 mb-2">{errors.email.message}</span>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-700"
              >
                Nombre*
              </label>
              <TextInput
                placeholder="Nombre"
                label="name"
                type="text"
                register={register}
                minLength={{ value: 1, message: 'El nombre no puede estar vacio' }}
                error={errors.name}
              />
              {errors.name?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="text-sm text-red-600 mb-2">{errors.name.message}</span>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="lastname"
                className="block text-sm font-semibold text-gray-700"
              >
                Apellidos*
              </label>
              <TextInput
                placeholder="Apellidos"
                label="lastname"
                type="text"
                register={register}
                error={errors.lastname}
              />
              {errors.lastname?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="text-sm text-red-600 mb-2">{errors.lastname.message}</span>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-700"
              >
                Contraseña*
              </label>
              <PasswordInput
                placeholder="Contraseña"
                label={'password'}
                className='mt-2'
                register={register}
                required={true}
                minLength={{ value: 1, message: 'La contraseña no puede estar vacia' }}
                error={errors.password}
              />
              {errors.password?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="text-sm text-red-600 mb-2">{errors.password.message}</span>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-semibold text-gray-800"
              >
                Confirmar Contraseña*
              </label>
              <PasswordInput
                placeholder="Confirmar contraseña"
                label={'confirmPassword'}
                className='mt-2'
                register={register}
                required={true}
                minLength={{ value: 8, message: 'La contraseña no puede estar vacia' }}
                error={errors.confirmPassword}
              />
              {errors.confirmPassword?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="text-sm text-red-600 mb-2">{errors.confirmPassword.message}</span>
                </div>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="checkLegal"
                className="text-sm font-medium text-gray-700"
              >
                <input
                  type='checkbox'
                  required={true}
                  className='accent-primary-100 rounded-md cursor-pointer'
                  onChange={(e) => {
                    setCheckLegal(e.target.checked);
                  }}
                />
                <span className='md:ml-2 text-xs text-neutral-600'>
                  Aceptar
                  <a href='https://www.autenticatest.com/terminos-y-condiciones/'
                    target="_blank"
                    rel="noreferrer"
                    className="underline mx-1"
                  >
                    Términos y condiciones
                  </a>
                  y
                  <a href='https://www.autenticatest.com/politica-de-privacidad/'
                    target="_blank"
                    rel="noreferrer"
                    className="underline ml-1"
                  >
                    Política de Privacidad
                  </a>
                </span>
              </label>
              {errors.checkLegal?.message && (
                <div className="flex flex-row items-center pt-1 text-red-600 mb-2	">
                  <span className="text-sm text-red-600 mb-2">{errors.checkLegal.message}</span>
                </div>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor="checkEmail"
                className="block text-sm font-medium text-gray-700"
              >
                <input
                  type='checkbox'
                  required={false}
                  className='accent-primary-100 rounded-md cursor-pointer'
                  onChange={(e) => {
                    setCheckEmail(e.target.checked);
                  }}
                />
                <span className='md:ml-2 text-neutral-600 text-xs'>
                  Aceptar recibir información por correo electrónico
                </span>
              </label>
              {errors.checkEmail?.message && (
                <span className="text-sm text-red-600 mb-2">{errors.checkEmail.message}</span>
              )}
            </div>
            {registerError && showErrors && (
              <div className="flex flex-row items-center pt-1 text-red-600 mb-2">
                <span className="text-sm text-red-600 mb-2">{registerError}</span>
              </div>
            )}
            <div>
              <MainButton
                isSubmitButton={true}
                value="subscription"
                name="subscription"
                className="w-full"
                disabled={isSubmitDisabled()}
              >
                Acceder al pago
              </MainButton>
              <MainButton
                value="trial-mode"
                isSubmitButton={true}
                name="trial-mode"
                disabled={isSubmitDisabled()}
                className="w-full mt-3"
              >
                Probar 3 días gratis
              </MainButton>
            </div>
          </form>
          <p className="mt-8 text-xs font-light text-center text-gray-700">-
            ¿Ya tienes una cuenta?
            <a
              href="/login"
              className="ml-1 font-medium text-black-600 hover:underline"
            >
              ¡Inicia sesión!
            </a>
          </p>
        </>
      }
    </div>
  );



  async function redirectAccordingToEvent(event: BaseSyntheticEvent<object, any, any> | undefined) {
    if (hasChooseSubscription(event)) { await redirectToCheckout(); }
    else navigate('/')
  }

  function displayErrors(error: unknown) {
    const castedError = errorService.castError(error);
    setRegisterError(castedError.toString());
    setShowErrors(true);
  }

  function isSubmitDisabled(): boolean {
    return checkLegal && !errors.email && email.length > 0 && password.length >= 8 && name.length > 0 && confirmPassword.length >= 8 && confirmPassword === password ? false : true;
  }

  async function redirectToCheckout(): Promise<void> {
    const createSesion = await createCheckoutSession(email);
    await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
  }

  function hasChooseSubscription(event: BaseSyntheticEvent<object, any, any> | undefined): boolean {
    const submitName = getSubmitName(event);
    return submitName != "no-subscription"
  }

  function getSubmitName(event: BaseSyntheticEvent<object, any, any> | undefined): string {
    const e = event!.nativeEvent;
    const sub = e as SubmitEvent
    return sub!.submitter!.getAttribute("name")!
  }

};
