import iconoSimulacro from '../../../assets/icons/iconosimulacro.png';
import iconoNormativa from '../../../assets/icons/logonormativa.png';
import { useEffect, useState } from 'react';
import UserService from '../../services/httpServices/user.service';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import MainButton from '../../components/buttons/MainButton';
import { FLOATING_BUTTON } from '../../config/constants';
import { GoChevronLeft } from 'react-icons/go';
import { useSubscription } from '../../contexts/subscriptionContext';
import { COMPONENTS_TYPES } from '../../config/constants';
import testLawSub from '../../../assets/icons/test-law-sub.svg'
import testsLawConcret from '../../../assets/icons/tests-law-concret.svg'
import Skeleton from 'react-loading-skeleton';


interface SelectTypeProps {
  type: string;
}


export default function SelectType(props: SelectTypeProps): JSX.Element {

  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const { getInfoUser } = UserService();
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [trialModeActive, setTrialModeActive] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser(user);
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    (async () => {
      try {
        const hasSubscription = await subscription.isActive()
        setSubscriptionActive(hasSubscription)
        const trialMode = await subscription.isTrialModeActive();
        setTrialModeActive(trialMode)
        getUserData();
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (!subscriptionActive && !trialModeActive) navigate('/tests/simulacrum')
    }
  }, [auth.user])

  return (
    <div className="relative flex flex-col flex-1">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? navigate(-1) : navigate('/')}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      {isLoading ?
        (
          <div className="flex-1 flex flex-col lg:flex-row">
            <div className="p-10 flex-1 flex flex-col items-center justify-center">
              <Skeleton height={50} width={200} />
              <Skeleton circle height={32} width={32} className='my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]' />
              <Skeleton height={30} width={300} />
            </div>
            <div className="p-10 flex-1 flex flex-col items-center justify-center  bg-primary-100 ">
              <Skeleton height={50} width={200} />
              <Skeleton circle height={32} width={32} className='my-3 lg:my-0 lg:mt-5 lg:mb-[10vh]' />
              <Skeleton height={30} width={300} />
            </div>
          </div>
        ) :
        <>
          <div className="flex-1 flex flex-col lg:flex-row">
            <div
              className="p-10 transition-all ease-in-out text-base hover:text-xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-white cursor-pointer text-primary-100"
              onClick={() => props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? navigate('/tests/regulations/law') : navigate('/tests/simulacrum')}>

              {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? <img
                className="h-40 w-40"
                src={testsLawConcret}
              /> : <img
                className="h-40 w-40"
                src={iconoSimulacro}
              />}
              <div className="text-center font-semibold text-4xl">
                {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? 'Toda la normativa' : 'Simulacro'}
              </div>
              <div className="text-center font-semibold lg:max-w-[25vw]">
                {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? 'Tests de una norma concreta' : '  Prueba de Examen real'}
              </div>
            </div>
            <div
              className="p-10 transition-all text-base ease-in-out hover:text-xl duration-[3s] flex-1 hover:flex-[2] flex flex-col items-center justify-center bg-primary-100 cursor-pointer text-white"
              onClick={() => props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? navigate(`/tests/regulations/custom`) : navigate(`/tests/regulations`)}>

              {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? <img
                className="h-40 w-40"
                src={testLawSub}
              />
                :
                <img
                  className="h-40 w-40"
                  src={iconoNormativa}
                />}
              <div className="text-center font-semibold text-4xl">
                {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? 'Normativa por niveles' : 'Normativa'}
              </div>
              <div className="text-center font-semibold lg:max-w-[25vw]">
                {props.type === COMPONENTS_TYPES.SELECT_CUSTOM_OR_LAW ? 'Tests de las normas de cada subgrupo' : ' Examen específico de normativa'}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}