import React, { useEffect } from 'react';
import { useAuth } from '../contexts/authContext';
import UserService from '../services/httpServices/user.service';

const SubscriptionContext = React.createContext();

export const SubscriptionProvider = ({ children }) => {

  const { checkSuscriptionStatus, checkUserTrial } = UserService();
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(null);
  const [endTrialDate, setEndTrialDate] = React.useState(null);

  const auth = useAuth();

  useEffect(() => {
    (async () => {
      if (auth.user) {
        const res = await checkSuscriptionStatus();
        setSubscriptionStatus(res);
      }
    })();
  }, [auth.user]);

  const isActive = async () => {
    if (auth.user) {
      const status = await checkSuscriptionStatus();
      return status == "active"
    }
  }

  const paymentHasFailed = async () => {
    if (auth.user) {
      const status = await checkSuscriptionStatus();
      return status == "unpaid"
    }
  }

  const hasSubscription = async () => {
    if (auth.user) {
      const status = await checkSuscriptionStatus();
      return status != "canceled"
    }
  }

  const isTrialModeActive = async () => {
    if (auth.token) {
      const status = await checkUserTrial();
      setEndTrialDate(status.endTrialDate);
      return status.isTrialModeActive
    }
  }
  const getStatusRenderableString = () => {
    if (hasSubscription()) {
      switch (subscriptionStatus) {
        case 'active':
          return "Suscrito"
        case 'unpaid':
          return "Problemas de pago"
        case 'trialing':
          return "Prueba"
        default:
          return 'No suscrito'
      }
    } else {
      return 'No suscrito'
    }
  }

  return (
    <SubscriptionContext.Provider value={{ isActive, paymentHasFailed, hasSubscription, getStatusRenderableString, isTrialModeActive, endTrialDate }}>
      {children}
    </SubscriptionContext.Provider>
  )
};

export const useSubscription = () => {
  return React.useContext(SubscriptionContext);
}