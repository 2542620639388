import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import UserService from '../../services/httpServices/user.service';
import { useAuth } from "../../contexts/authContext";
import { TrialModeFinished } from "./../home/SubscriptionFailureModals";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from './../../contexts/subscriptionContext';



const TimerComponent = ({ expiryTimestamp, onExpire }: { expiryTimestamp: Date, onExpire: () => void }) => {
    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp,
        onExpire,
    });
    // console.log(expiryTimestamp)
    return (
        <div className="flex items-center justify-center gap-2 bg-primary-300 px-3 py-1 text-primary-foreground text-sm font-medium transition-all duration-60 ease-in-out ">
            <span>Tu período de prueba termina en: </span>
            <div>
                <span className="font-bold">{days}</span>d <span className="font-bold">{hours}</span>h{" "}
                <span className="font-bold">{minutes}</span>m <span className="font-bold">{seconds}</span>s
            </div>
        </div>
    );
};

const PillTrialMode = () => {
    const { disableTrialMode } = UserService();
    const { user } = useAuth();
    const navigate = useNavigate()
    const { isTrialModeActive, endTrialDate } = useSubscription();
    const [trialModeDisableModal, setTrialModeDisableModal] = useState(false);
    const [expiryTimestamp, setExpiryTimestamp] = useState<any>(null);
    const simulatedExpiryTimestamp = new Date(Date.now() + 20 * 1000)
    const [trialModeActive, setTrialModeActive] = useState(false);
    const [animationClass, setAnimationClass] = useState('');



    useEffect(() => {
        if (endTrialDate) {
            const endTimestamp = new Date(endTrialDate);
            setExpiryTimestamp(endTimestamp);
        }
    }, [endTrialDate]);

    useEffect(() => {
        (async () => {
            try {
                if (user) {
                    const trialMode = await isTrialModeActive();
                    setTrialModeActive(trialMode)
                    if (trialMode) {
                        setAnimationClass('fade-in');
                    }
                }

            } catch (err) {
                console.log(err)
            }

        })();
    }, [user])

    const disableTrial = async () => {
        try {
            await disableTrialMode(user.email);
        } catch (err) {
            console.log(err);
        } finally {
            if (window.location.pathname === '/') {
                window.location.reload();
            } else {
                setTrialModeDisableModal(true);
            }
        }
    };
    if (!user) {
        return null;
    }
    return (
        <>
            {trialModeDisableModal && <TrialModeFinished onClose={() => navigate('/')} />}
            {trialModeActive && expiryTimestamp && (
                <div className={animationClass}>
                    <TimerComponent expiryTimestamp={expiryTimestamp} onExpire={disableTrial} />
                </div>
            )}
        </>
    );
};

export default PillTrialMode;
