export default function MainButton(props) {

  const baseStyle = `select-none ${!props.disabled && ' cursor-pointer '} text-sm whitespace-nowrap font-medium flex items-center justify-center gap-1 max-h-[40px]`

  const styles = {
    primary: "bg-primary-100 py-2.5 px-3.5 text-white rounded-md hover:enabled:ring-2 hover:enabled:ring-primary-100 hover:enabled:bg-white hover:enabled:text-primary-100 transition",
    secondary: "py-2.5 px-3.5 ring-1 ring-primary-100 text-primary-100 rounded-md hover:enabled:bg-gray-100 transition",
    secondary_desist: "py-2.5 px-3.5 ring-1 ring-red-500 text-red-500 rounded-md hover:enabled:bg-red-100 transition",
    secondary_gray: "py-2.5 px-3.5 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition",
    desist: "bg-red-600 py-2.5 px-3.5 text-white rounded-md hover:enabled:ring-2 hover:enabled:ring-red-500 hover:enabled:bg-white hover:enabled:text-red-500 transition",
    transparent: "py-2.5 text-primary-100 rounded-md hover:enabled:underline transition",
    round: "bg-primary-100 py-2 px-4 text-white rounded-full ring-white ring-2 hover:enabled:ring-primary-100 hover:enabled:bg-white hover:enabled:text-primary-100 transition",
    round_red: "bg-red-400 py-2 px-4 text-white rounded-full hover:enabled:ring-2 hover:enabled:ring-red-400 hover:enabled:bg-white hover:enabled:text-red-400 transition",
    round_black: "bg-black py-2 px-4 text-white rounded-full hover:enabled:ring-2 hover:enabled:ring-black hover:enabled:bg-white hover:enabled:text-black transition",
    round_secondary: "bg-orange-300 py-2 px-4 text-white rounded-full hover:enabled:bg-orange-100 hover:enabled:text-gray-600 hover:enabled:ring-1 hover:enabled:ring-gray-600 transition",
    round_white: "bg-white py-2 px-4 text-black rounded-full hover:enabled:ring-1 hover:enabled:ring-black transition",
    icon: "text-black hover:enabled:text-gray-300 transition rounded-md",
    admin: "bg-yellow-300 py-2.5 px-4 text-white rounded-full hover:enabled:bg-amber-200 transition",
    floating: "bg-white py-1.5 px-4 text-text-100 rounded-full transition-all hover:enabled:pr-8 hover:enabled:gap-4",
  }

  const clickHandler = (e) => {
    if (props.onClick) props.onClick();
  }


  return (
    <button
      value={props.value}
      name={props.isSubmitButton && 'submit'}
      type={props.isSubmitButton ? 'submit' : 'button'}
      className={`${baseStyle} ${props.type ? styles[props.type] : styles['primary']} ${props.className} ${props.disabled && 'opacity-40'}`}
      onClick={clickHandler}
      disabled={props.disabled}>
      {props.children}
    </button>
  )
}