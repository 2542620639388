import { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/authContext';
import { useSubscription } from '../../../contexts/subscriptionContext';
import { NoPaidModal } from '../SubscriptionFailureModals';
import { PrincipalFeature } from './PrincipalFeature';


export default function LoggedHomeComponent() {

  const subscription = useSubscription();
  const [paymentFailed, setPaymentFailed] = useState(false);

  useEffect(() => {
    (async () => {
      const hasFailed = await subscription.paymentHasFailed();
      setPaymentFailed(hasFailed);
    })();
  }, [subscription]);

  return (
    <>
      {paymentFailed &&
        <NoPaidModal />
      }
      <PrincipalFeature />
    </>
  );
}
