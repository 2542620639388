import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/authContext";
import PaymentService from "../../../services/httpServices/payment.service";
import paymentServiceStripe from "../../../services/paymentStripe.service";
import { useSubscription } from "../../../contexts/subscriptionContext";
import { useEffect, useState } from "react";


export default function TrailingBanner(): JSX.Element {

    const { createCheckoutSession } = PaymentService();
    const navigate = useNavigate();
    const auth = useAuth();
    const subscription = useSubscription();
    const [isLoggedAndSubscribed, setIsLoggedAndSubscribed] = useState(false);

    async function redirect(): Promise<void> {
        if (auth.token) {
            await redirectToCheckout();
        } else {
            redirectoToRegister()
        }
    }

    async function redirectToCheckout(): Promise<void> {
        const createSesion = await createCheckoutSession(auth.user.email);
        await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
    }

    async function redirectoToRegister() {
        navigate('/register')
    }

    function checkLoggedAndSubscribed(): void {
        const isLoggedAndSubscribed = auth.token && subscription.isActive();
        setIsLoggedAndSubscribed(isLoggedAndSubscribed);
        return isLoggedAndSubscribed; // Added return statement
    }
    useEffect(() => {
        checkLoggedAndSubscribed()
    }, [auth]);


    return (
        <>
            {
                isLoggedAndSubscribed ?
                    <p className="cursor-pointer border-t-[1px] border-gray-200 text-text-600 text-center flex w-screen justify-center items-center px-4 py-4 bg-background-300">
                        Estás realizando un test en modo de prueba
                    </p>
                    :
                    <div className="cursor-pointer border-t-[1px] border-gray-200 text-text-600 text-center flex w-screen justify-center items-center px-4 py-4 bg-background-300" onClick={redirect}>
                        Estás realizando un test en modo de prueba, para disfrutar de todas las ventajas de Auténtica Test suscríbete
                        pulsando&nbsp;
                        <u>
                            aquí
                        </u>
                    </div>
            }
        </>
    );
};
